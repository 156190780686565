export const courseDetails = [
    {
        id: 1,
        thumbnail: 'sample-picture-2.jpg',
        about: 'This course is designed to allow qualified plumbers and heating engineers to gain the knowledge and skills needed to install Air source heat pumps either by installing new installations or retro fitting existing installations. Completing this course will allow installers to apply and join the “Consumer Code” and “MCS” Scheme',
        courseFee: 'FREE',
        serviceFee: '9.99',
        templateComponentName: 'AirSourceHeatPumpTemplate',
    },
    {
        id: 2,
        thumbnail: 'energy-efficiency-for-domestic-heating-hero.jpg',
        about: 'This course has been designed for individuals looking to understand and gain the knowledge to work on the supply, design, installation, commissioning and handover of central heating systems.',
        courseFee: '180',
        serviceFee: '9.99',
        templateComponentName: 'EnergyEfficiencyForDomesticHeatingTemplate',
    },
    {
        id: 3,
        thumbnail: 'solar-pv-installation-hero.jpg',
        about: 'This course is for those wishing to achieve a nationally recognised qualification in the installation and maintenance of Solar Photovoltaic systems. This qualification is based upon the National Occupational Standards as published by industry and is recognised by the Microgeneration Certification Scheme registration bodies as an acceptable qualification to join the scheme.',
        courseFee: 'FREE',
        serviceFee: '9.99',
        templateComponentName: 'SolarPvInstallationAndMaintenanceTemplate',
    },
    {
        id: 4,
        thumbnail: 'water-regulation-hero.jpg',
        about: 'This course was developed to enable plumbing and heating engineers to confirm their competence in installing and maintaining unvented hot water storage systems. On successful completion of this course, it will allow candidates’ membership to an Unvented Hot Water Competent Persons Scheme.',
        courseFee: 'FREE',
        serviceFee: '9.99',
        templateComponentName: 'UnventedHotWaterTemplate',
    },
    {
        id: 5,
        thumbnail: 'water-regulation-hero.jpg',
        about: 'This course is for heating and plumbing installers who need to gain the knowledge of the Water Regulations (1999) before applying to join their local water authorities “Water Industry Approved Plumber Scheme”, one of the plumbing competent person schemes (CPS) or Microgeneration Certification Scheme (MCS) registration bodies.',
        courseFee: 'FREE',
        serviceFee: '9.99',
        templateComponentName: 'WaterRegulationsTemplate',
    },
];
