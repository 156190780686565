<template>
    <div
      class="status-state flex-row ai-c gap-half w-auto"
      :class="{'is-enrolled': isEnrolled}">
      <el-icon :size="17" color="#FFF"><SuccessFilled/></el-icon>
      <Typography
        variant="custom"
        textSize="12px"
        textWeight="400"
        textColor="#FFF"
        whiteSpace="nowrap">
        {{ label }}
      </Typography>
    </div>
</template>
<script>
import { SuccessFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';

import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    SuccessFilled,
    Typography
  },

  props: {
    label: String,
    isEnrolled: Boolean
  }
});
</script>
<style lang="scss" scoped>
.status-state {
    border-radius: 8px;
    background: #1FB27A;
    box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08);
    padding: 2px 7px;
    box-sizing: border-box;
}
.status-state.is-enrolled {
  background: #0c4e35;
}
</style>
