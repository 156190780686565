<template>
    <div class="chip-state flex-row ai-c gap-half">
      <Icon :customPath="iconCustomPath" :iconName="iconName" v-if="!hideIcon"/>
      <Typography
        class="flex-row ai-c gap-half"
        :class="{'flex-reverse': isReverse}"
        whiteSpace="nowrap"
        variant="custom" textSize="12px" textWeight="400" :textColor="textColor">
        <span :class="{'crash-label': crashLabel}">{{ label }}</span>
        <span style="font-weight: 700 !important" v-if="labelSpan">{{ labelSpan }}</span>
      </Typography>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    Icon,
    Typography
  },

  props: {
    iconCustomPath: {
      type: String,
      default: 'icons'
    },

    iconName: {
      type: String,
      default: 'add.svg'
    },

    label: String,
    labelSpan: String,

    textColor: {
      label: String,
      default: '#0C0F4A'
    },

    hideIcon: Boolean,

    crashLabel: Boolean,

    isReverse: Boolean
  }
});
</script>
<style lang="scss" scoped>
  .flex-reverse {
    flex-direction: row-reverse !important;
  }
  .crash-label {
    position: relative;
    &::before {
        content: "";
        width: 100%;
        height: 2px;
        background: v-bind('$props.textColor');
        position: absolute;
        top: 40%;
        left: 0;
    }
  }
</style>
